<template>
  <div class="pagecontent-box">
    <div class="yp-position-box">
      <div class="col-left-title">
        <p :title="info.name">{{ info.name?info.name:'暂无' }}</p>
      </div>
      <div class="info-box">
        <div class="yp-position-nbox" id="scrollBox">
          <div class="yp-info-box applications-content">
              2344
          </div>
        </div>
      </div>

    </div>
  </div>


</template>

<script>
  import {  } from "@/api/data"
import { json } from "d3";
  export default {
    name: 'cedicalCalculators',
    components: {

    },
    data(){
      return {
        viewHeight:'',
        viewWidth:'',
        sickNess1:[],
        is_view: true,
        main_bg:{},
        tag_pages:'xyzsk',
        name:'',
        tag:'',
        info: null
      }
    },
    mounted(){
      let that = this;
    },
    created(){
      let getViewportSize = this.$getViewportSize();
      this.viewHeight = getViewportSize.height;
      this.viewWidth = getViewportSize.width;
      this.main_bg = this.$root.main_bg;  // 背景图
      this.tag = this.$route.query.tag?this.$route.query.tag:'';
      this.info = JSON.parse(this.$route.query.item);
      document.title = this.info.name;
      this.getNewBaseDetail();
    },

    methods: {
      setsickNess(){
        this.is_view = false;
        this.$nextTick(() => {
          this.is_view = true
          console.log(this.is_view)
        })
      },
      //获取西医知识库详情页数据
      getNewBaseDetail(){
        let that = this;
        
       
      },
    },
  }
</script>
<style lang="scss" scoped>


</style>
